<template>
  <v-select
    solo
    flat
    dense
    class="select-filter"
    :items="sortOptions"
    :value="value"
    @change="handleSortFilter"
    :label="$t('$vuetify.dataTable.sortBy')"
    hide-details
  >
    <template v-slot:selection v-if="$vuetify.breakpoint.xs">
      <span
        class="font-weight-bold text-caption"
        v-html="$t('$vuetify.dataTable.sortBy')"
      />
    </template>
    <template v-slot:append>
      <v-icon x-small>$chevronDown</v-icon>
    </template></v-select
  >
</template>
<style scoped lang="scss">
.select-filter {
  :deep(.v-input__slot) {
    padding: 0 !important;
  }
}
.text-caption {
  font-size: 12px !important;
}
</style>
<script>
import cloneDeep from "lodash/cloneDeep";
export default {
  name: "ProductSortFilter",
  data() {
    return {
      value: "",
      sortOptions: [
        { value: "", text: "Rilevanza", icon: "ion-arrow-up-c" },
        { value: "pri_asc", text: "Prezzo crescente", icon: "ion-arrow-up-c" },
        {
          value: "pri_des",
          text: "Prezzo decrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "pri_um_asc",
          text: "Prezzo al kg crescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "pri_um_des",
          text: "Prezzo al kg decrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "alf_asc",
          text: "Alfabetico crescente",
          icon: "ion-arrow-up-c"
        }
      ]
    };
  },
  props: ["sortFilter"],
  methods: {
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    }
  },
  mounted() {
    if (this.sortFilter) {
      this.value = cloneDeep(this.sortFilter);
    }
  }
};
</script>

<style scoped lang="scss">
.select-filter {
  max-width: 250px;
  :deep .v-input__slot {
    background: transparent !important;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    max-width: 130px;
    :deep .v-select__selection {
      font-size: 12px !important;
      margin: 2px 4px 3px 0 !important;
    }
  }
}
</style>
