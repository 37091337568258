<template>
  <div>
    <transition-group name="fade" mode="out-in">
      <v-checkbox
        class="panel-checkbox"
        multiple
        hide-details
        v-for="value in filter"
        :key="value.id"
        :value="value.id"
        :disabled="value.filteredCount == 0"
        v-model="selectedFacetsXS[facet.parameterName]"
        :label="`${value.name} (${value.filteredCount})`"
        @click="
          handleFacetFilterMobile(
            facet.name,
            value.name,
            selectedFacetsXS[facet.parameterName].length
          )
        "
      >
      </v-checkbox>
    </transition-group>
    <a
      v-if="facet.values.length > 6 && panelLength == 6"
      class="caption d-flex align-center justify-center mt-2 primary--text"
      @click="panelLength = facet.values.length"
    >
      <span class="mr-2">Mostra di più</span>
      <v-icon color="primary lighten-1" x-small>$chevronDown</v-icon>
    </a>
    <a
      v-else-if="facet.values.length > 6"
      class="caption d-flex align-center justify-center mt-2 primary--text"
      @click="panelLength = 6"
    >
      <span class="mr-2">Mostra di meno</span>
      <v-icon color="primary lighten-1" x-small>$chevronUp</v-icon>
    </a>
  </div>
</template>
<style>
.panel-checkbox {
  overflow: hidden;
}
.fade-enter {
  max-height: 0;
  opacity: 0;
}

.fade-enter-active {
  animation: fadein 0.4s;
}

.fade-leave {
  max-height: 500px;
  opacity: 1;
}

.fade-leave-active {
  animation: fadein 0.4s reverse;
}

@keyframes fadein {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 500px;
    opacity: 1;
  }
}
</style>
<script>
import cloneDeep from "lodash/cloneDeep";
export default {
  name: "FilterCheckboxList",
  data() {
    return {
      panelLength: 6
    };
  },
  props: {
    facet: { type: Object },
    facetsFilter: { type: Object }
  },
  computed: {
    selectedFacetsXS() {
      return cloneDeep(this.facetsFilter);
    },
    filter() {
      return this.facet.values.slice(0, this.panelLength);
    }
  },
  methods: {
    handleFacetFilterMobile(filterType, filterValue, active) {
      this.trackFilter(filterType, filterValue, active);
      this.$emit("handleFacetFilterMobile", this.selectedFacetsXS);
    },
    trackFilter(filterType, filterValue, active) {
      console.log(filterType, filterValue, active);
      const event = active
        ? "applicazione-filtro-listing"
        : "rimozione-filtro-listing";
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event,
        filterType,
        filterValue
      });
    }
  }
};
</script>
