<template>
  <div class="product-grid">
    <v-row
      class="sorting-row mobile d-flex d-sm-none"
      justify="space-between"
      align="center"
    >
      <v-col cols="4" v-if="!hideFacetsFilter" class="pr-0">
        <ProductFacetFilterMobile
          :facets="facets"
          :facetsFilter="facetsFilter"
          :promoFilter="promoFilter"
          :newFilter="newFilter"
          :filteredProductsCount="filteredProductsCount"
          :hideFilterString="hideFilterString"
          @handlePromoFilter="handlePromoFilter"
          @handleNewFilter="handleNewFilter"
          @handleFacetFilterMobile="handleFacetFilterMobile"
          :icon="''"
          :loading="loading"
        >
          <slot name="category-block-position-3"></slot>
          <slot name="category-block-position-4"></slot>
        </ProductFacetFilterMobile>
      </v-col>
      <v-col :cols="hideFacetsFilter ? 6 : 4" class="px-0">
        <span
          class="text-caption text-sm-body-2 font-weight-bold d-flex flex-row justify-center text-center"
        >
          {{ filteredProductsCount }} prodotti
        </span>
      </v-col>

      <v-col
        :cols="hideFacetsFilter ? 6 : 4"
        class="d-flex flex-row justify-end"
      >
        <ProductSortFilter
          :sortFilter="sortFilter"
          @handleSortFilter="handleSortFilter"
          class="product-sort-filter"
        />
      </v-col>
    </v-row>

    <v-row class="mt-0 mt-sm-3">
      <v-col
        class="hidden-xs-only px-0"
        cols="12"
        sm="4"
        lg="3"
        xl="2"
        v-if="!hideFacetsFilter"
      >
        <div ref="filters" class="px-3 filter-sticky-column">
          <h4 class="primary--text text--lighten-1">Filtra i prodotti</h4>
          <v-divider />
          <ProductPromoFilter
            v-if="!hidePromoFilter"
            :promoFilter="promoFilter"
            :newFilter="newFilter"
            @handlePromoFilter="handlePromoFilter"
            @handleNewFilter="handleNewFilter"
          />
          <ProductFacetFilterDesktop
            v-if="$vuetify.breakpoint.smAndUp"
            :facets="facets"
            :facetsFilter="facetsFilter"
            :filteredProductsCount="filteredProductsCount"
            :hideFilterString="hideFilterString"
            @handleFacetFilterMobile="handleFacetFilterMobile"
          />
          <slot name="category-block-position-3"></slot>
          <slot name="category-block-position-4"></slot>
        </div>
      </v-col>
      <v-col
        cols="12"
        :sm="hideFacetsFilter ? 12 : 8"
        :lg="hideFacetsFilter ? 12 : 9"
        :xl="hideFacetsFilter ? 12 : 10"
      >
        <slot name="category-block-position-1"></slot>
        <slot name="category-title"></slot>
        <!-- <category-title
          class="mt-2 mt-sm-0"
          v-if="category && mode != 'search'"
          :category="category"
          :showSub="true"
          :isTitleForBrand="titleLayout === 'brand'"
          :height="categoryTitleHeight"
          :expandedDescription="expandedDescription"
          :lockDescription="lockDescription"
        />
        <div v-else>
          <div
            class="text-h4 primary--text text--lighten-1 text-center text-sm-left font-weight-bold mt-4 mt-sm-0"
          >
            Risultati ricerca per {{ searchedText }}
          </div>
          <p class="subtitle-1" v-if="filter">in {{ filter }}</p>
          <p class="subtitle-2" v-if="this.pager.searchedText">
            Avevi cercato
            <a class="real_search">{{ this.query }}</a>
            ma non ha prodotto risultati.
          </p>
        </div> -->

        <!-- <v-row
          no-gutters
          v-if="description"
          justify="end"
          class="text-body-2 primary--text text--lighten-2 font-weight-bold"
        >
          <span @click="expandedDescription = !expandedDescription">{{
            $t(
              `products.description.${
                expandedDescription ? "collapse" : "expand"
              }`
            )
          }}</span>
          <v-icon small color="primary lighten-2">{{
            `$chevron${expandedDescription ? "Up" : "Down"}`
          }}</v-icon>
        </v-row> -->

        <v-row
          no-gutters
          v-if="filteredFacets.length"
          align="center"
          class="mt-3"
        >
          <v-col cols="12" md="2" lg="1">
            <span class="text-body-2 default--text font-weight-bold mr-3"
              >{{ $t("filter.yourFilters") }}
            </span>
          </v-col>
          <v-col
            cols="12"
            md="10"
            lg="11"
            class="d-flex flex-row flex-wrap align-center"
          >
            <v-chip
              outlined
              close
              color="primary lighten-1"
              close-icon="$close"
              small
              label
              v-for="(facetId, idx) in filteredFacets"
              :key="idx"
              @click:close="removeFilter(facetId)"
              class="pa-3 mr-2 my-1"
            >
              {{ getSelectedFacet(facetId).name }}
            </v-chip>
            <v-divider
              vertical
              class="mr-2 grey my-2"
              v-if="filteredFacets.length > 1"
            />
            <v-chip
              v-if="filteredFacets.length > 1"
              outlined
              close
              color="primary lighten-1"
              close-icon="$close"
              small
              label
              class="pa-3 mr-2 my-1"
              @click:close="clearAllFilters"
              >{{ $t("filter.button.clearAll") }}</v-chip
            >
          </v-col>
        </v-row>

        <!-- </v-layout> -->
        <v-row
          no-gutters
          justify="center"
          justify-sm="space-between"
          align="center"
          class="sorting-row mb-3 mt-2 d-none d-sm-flex"
        >
          <span
            class="text-caption text-sm-body-2 font-weight-bold text-uppercase"
          >
            {{ filteredProductsCount }} prodotti
          </span>
          <!-- <v-spacer /> -->
          <!-- <v-col cols="0" sm="6" lg="6" class=""> -->
          <div
            class="d-flex flex-row align-center"
            v-if="!$vuetify.breakpoint.xs"
          >
            <span class="text-caption sort-filter-label">
              Ordina prodotti per:
            </span>
            <ProductSortFilter
              v-if="!hideSort && $vuetify.breakpoint.smAndUp"
              :sortFilter="sortFilter"
              @handleSortFilter="handleSortFilter"
              class="product-sort-filter"
            />
          </div>
        </v-row>
        <div style="max-height: 100%" v-if="totItems > 0">
          <product-grid :productList="products" :key="key" />
        </div>
        <div v-else class="pa-12 text-center">
          <span v-if="promoFilter">Nessun prodotto in promozione</span>
          <span v-else>Nessun prodotto trovato</span>
        </div>
        <v-btn
          block
          v-intersect="onIntersect"
          :loading="loading"
          @click="loadMore"
          v-if="$vuetify.breakpoint.xs && hasMoreItems"
        >
          <template v-if="hasMoreItems">
            {{ $t("products.showMore") }}
          </template>
          <template v-else>{{ $t("products.showAll") }}</template>
        </v-btn>

        <v-pagination
          v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
          :value="pageFilter"
          :page="pager.selPage"
          :length="pager.totPages ? pager.totPages : 0"
          :totalVisible="7"
          @next.stop.prevent="handlePageFilter"
          @prev="handlePageFilter"
          @input="handlePageFilter"
          class="my-8"
        ></v-pagination>
        <slot name="category-block-position-5"></slot>
        <v-container v-if="footerText">
          <div v-html="footerText" class="footer-text" />
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
.product-grid {
  .sort-filter-label {
    width: 100%;
    max-width: fit-content;
  }
  .promo-box.promo_discount.tap.blu .promo-body,
  .promo-box.promo_discount.tap.rosso .promo-body,
  .promo-box.promo_discount.tap.giallo .promo-body,
  .promo-box.promo_discount.tap.verde .promo-body {
    font-style: italic;
  }

  .product-sort-filter {
    height: 42px;
    .v-select__selection {
      font-size: 12px;
      font-weight: bold;
      &.v-select__selection--comma {
        margin-bottom: 1px !important;
      }
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      height: 28px;

      .v-input__control {
        min-height: 28px !important;
        label {
          color: #000;
          font-size: 11px;
          text-transform: uppercase;
        }
        i {
          color: #000;
        }
        .v-select__selection {
          font-size: 12px;
        }
        .v-select__slot {
          width: 95px;
        }
      }
    }
  }

  .footer {
    background: transparent;
    h2 {
      color: var(--v-primary-lighten1);
      font-weight: normal;
      font-size: 31px;
    }
  }

  .footer-text {
    background-color: transparent !important;
    h2 {
      color: var(--v-primary-lighten1) !important;
      font-weight: 400;
      font-size: 31px;
      margin-top: 14px;
      margin-bottom: 7px;
    }
  }
}

.sorting-row {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-top: 1px solid var(--v-grey-lighten2);
    border-bottom: 1px solid var(--v-grey-lighten2);
  }
  &.mobile {
    background-color: #e6e6e6;
    height: 50px;
    .col {
      padding: 5px 5px 5px 0;
    }
    ::v-deep .v-input {
      background-color: #e6e6e6 !important;
    }
  }
}
</style>
<script>
import ProductSortFilter from "@/components/product/filters/ProductSortFilter";
import ProductPromoFilter from "@/components/product/filters/ProductPromoFilter";

import ProductFacetFilterMobile from "./filters/ProductFacetFilterMobile.vue";
import ProductFacetFilterDesktop from "./filters/ProductFacetFilterDesktop.vue";
import ProductGrid from "./ProductGrid.vue";

import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";

import { mapProposal } from "~/service/ebsn.js";
import forEach from "lodash/forEach";
import cloneDeep from "lodash/cloneDeep";
import remove from "lodash/remove";
import { mapGetters } from "vuex";

export default {
  name: "ProductList",
  props: {
    parentCategoryId: { type: Number, required: false },
    categoryId: { type: Number, required: false },
    query: { type: String, required: false },
    barcode: { type: String, required: false },
    mode: { type: String, required: false },
    hidePromoFilter: { type: Boolean, default: false },
    hideFilterString: { type: String, required: false },
    hideSort: { type: Boolean, default: false },
    hideFacetsFilter: { type: Boolean, default: false },
    filter: { type: String, required: false },
    position: { type: String, required: false },
    footerText: { type: String }
  },
  components: {
    ProductSortFilter,
    ProductPromoFilter,
    ProductFacetFilterMobile,
    ProductFacetFilterDesktop,
    ProductGrid
  },
  data() {
    return {
      products: [],
      facets: [],
      pager: {},
      page: 1,
      busy: false,
      loading: false,
      filteredProductsCount: 0,
      filteredFacets: [],
      key: 0
    };
  },
  computed: {
    ...mapProposal({
      sideboxBanner: "sidebox-banner"
    }),
    queryLabel() {
      return this.barcode ? this.barcode : this.query;
    },
    currentRouteName() {
      return this.$route.name;
    },
    totItems() {
      return this.pager && this.pager.totItems ? this.pager.totItems : 0;
    },
    ...mapGetters({
      promoFilter: "category/promoFilter",
      newFilter: "category/newFilter",
      sortFilter: "category/sortFilter",
      pageFilter: "category/pageFilter"
    }),
    facetsFilter: {
      get() {
        return this.$store.state.category.filters.facets;
      },
      set() {
        // this.$store.commit("updatedDrawerLeft", value);
      }
    },

    hasMoreItems() {
      if (this.pager) {
        return this.pager.totPages > this.pager.selPage;
      } else {
        return false;
      }
    }
  },
  methods: {
    handleSortFilter(sort) {
      this.$store.dispatch("category/setFilterSort", sort);
      this.fetchProducts();
    },
    handleNewFilter(onlyNew) {
      this.$store.dispatch("category/setFilterNew", onlyNew);
      this.fetchProducts();
    },
    handlePromoFilter(promo) {
      this.$store.dispatch("category/setFilterPromo", promo);
      this.fetchProducts();
    },
    handleFacetFilter(filter) {
      this.$store.dispatch("category/setFilterFacets", {
        facets: filter.facets,
        parameterName: filter.parameterName
      });
      this.fetchProducts();
    },
    handleFacetFilterMobile(selectedFacetsXS) {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: selectedFacetsXS
      });
      this.fetchProducts();
    },
    handlePageFilter(page) {
      var _this = this;
      // console.log(page);
      this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      this.fetchProducts();
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    },

    setFilteredFacets() {
      this.filteredFacets = [];
      let temp = [];
      forEach(this.facets, facet => {
        if (
          typeof this.facetsFilter[facet.parameterName] !== "undefined" &&
          this.facetsFilter[facet.parameterName].length
        ) {
          temp.push(
            this.facetsFilter[facet.parameterName].map(id => {
              if (id) {
                return id;
              }
            })
          );
          this.filteredFacets = temp.flat();
        }
      });
    },

    getSelectedFacet(facetId) {
      if (facetId) {
        let item;
        forEach(this.facets, facet => {
          facet.values.filter(v => {
            if (v && v.id === facetId) {
              item = v;
            }
          });
        });
        return item;
      }
    },
    removeFilter(facetId) {
      let selectedFacetsXS = cloneDeep(this.facetsFilter);
      forEach(Object.keys(selectedFacetsXS), k => {
        let idsArray = selectedFacetsXS[k];
        if (idsArray.length) {
          remove(idsArray, v => {
            return v === facetId;
          });
        }
        if (idsArray.length < 1) {
          delete selectedFacetsXS[k];
        }
      });
      console.log(selectedFacetsXS);
      this.handleFacetFilterMobile(selectedFacetsXS);
    },
    clearAllFilters() {
      this.handleFacetFilterMobile({});
      this.filteredFacets = [];
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMoreItems) {
        this.$store.dispatch("category/setFilterPage", this.pageFilter + 1);
        this.fetchProducts(true);
      }
    },
    async fetchProducts(append) {
      this.loading = true;
      let response = await ProductService.search(
        {
          parent_category_id: this.parentCategoryId,
          category_id: this.categoryId,
          q: this.query,
          barcode: this.barcode,
          page: this.pageFilter,
          page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
          sort: this.sortFilter,
          promo: this.promoFilter,
          new_product: this.newFilter
        },
        this.facetsFilter
      );

      // Se ho letto il barcode e mi torna un Prodotto, navigo diretto al Dettaglio
      if (this.barcode && response.products.length > 0) {
        this.$router.push({
          name: "Product",
          params: { slug: response.products[0].slug }
        });
      } else if (append) {
        this.products = [...this.products, ...response.products];
      } else {
        this.products = response.products;
      }
      if (response.products.length > 0) {
        analyticsService.viewProducts(
          response.products,
          this.position,
          (response.page.selPage - 1) * response.page.itemsPerPage
        );
      }
      this.key++;
      this.pager = response.page;
      this.facets = response.facets;
      this.$emit("productsCount", this.pager.totItems);
      this.filteredProductsCount = response.page.totItems;
      if (this.pager.searchedText) {
        this.$emit("searchedText", this.pager.searchedText);
      }
      this.loading = false;
      this.setFilteredFacets();
    }
  },
  async created() {
    //se sono su mobile devo caricare le pagine fino alla corrente
    if (this.$vuetify.breakpoint.xsOnly) {
      let page = this.pageFilter;
      for (let i = 1; i <= page; i++) {
        this.$store.dispatch("category/setFilterPage", i);
        await this.fetchProducts(true);
      }
    } else {
      this.fetchProducts();
    }
  }
};
</script>
